import React, { useState } from "react";
import { RegisterStepIndicator } from "../components/register/registerstepindicator";
import { RegisterProvider } from "../components/register/registerContext/registerContext";
import RegisterSteps from "../components/register/registerSteps";
import { Button, FormGroup, Input } from "../components/common/form";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { AuthService } from "../api/auth/auth.http";
import { useLocation, useNavigate } from "react-router-dom";

type FormData = {
  email: string;
};

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<FormData>();

  const onSubmit = handleSubmit(async formData => {
    try {
      setIsLoading(true);
      const response: any = await AuthService.resetPass(formData);

      console.log(response);
      if (response.message) {
        toast.success(response.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      navigate("/setresetpassword" + `/?email=${formData.email}`);

      // Do something with the response, like redirect the user to the dashboard
    } catch (error) {
      if (error.data.message) {
        const message =
          typeof error.data.message === "string"
            ? error.data.message
            : Object.values(error.data.message)[0];
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // Handle the error, such as displaying an error message to the user
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <section>
      <ToastContainer />
      <h2 className="text-center mb-5 pb-3 mt-5">Reset password</h2>

      <div className="register_wrapper recoverPassword ">
        <form autoComplete="off" onSubmit={onSubmit}>
          <FormGroup
            errorMessage={
              errors.email?.type === "required"
                ? "Email is required"
                : errors.email?.type === "pattern"
                ? "Please enter a valid email address"
                : ""
            }
          >
            <Input
              onChange={() => clearErrors("email")}
              useRef={register("email", {
                required: "Email is required",
                pattern: {
                  value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email address",
                },
              })}
              hasError={!!errors.email}
              name="email"
              type="text"
              placeholder="example@mail.com"
            />
          </FormGroup>

          <div className="nextButton d-flex align-items-center  mt-4 justify-content-center ">
            {/* <div>
            <span>Already have an account?</span>
            <Link to={"/"}>Log in</Link>
          </div> */}
            <Button className="btn btn-default px-5" loading={isLoading}>
              Send
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}
