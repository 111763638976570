import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, FormGroup, Input } from "../components/common/form";
import { useCheckAuth } from "../utility/useCheckAuth";
import { useForm } from "react-hook-form";
import { AuthService } from "../api/auth/auth.http";
import { ToastContainer, toast } from "react-toastify";

type FormData = {
  currentPassword: string;
  password: string;
  passwordConfirm?: string;
};

export const Settings = () => {
  const [load, setLoad] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormData>();

  const [changePasswordStep, setChangePasswordStep] = useState(false);

  useEffect(() => {
    setValue("currentPassword", "");
  }, []);
  const {
    auth: { email },
  } = useSelector((state: any) => state);

  useCheckAuth();

  const onSubmit = handleSubmit(async formData => {
    setLoad(true);
    try {
      let res = await AuthService.changePassword({
        password: formData.password,
        current_password: formData.currentPassword,
      });
      toast.success("Password changed successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.log(err.data);
      if (err.data?.message?.current_password) {
        toast.error(err.data.message?.current_password, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setLoad(false);
    }
  });
  return (
    <div className="vpnForm">
      <ToastContainer />
      {!changePasswordStep ? (
        <form autoComplete="off">
          <h2>Account info</h2>
          <FormGroup Label="Email">
            <Input
              value={email}
              readonly={true}
              className="mb-4"
              placeholder="Emailexample@gmail.com"
            />
          </FormGroup>
          {/* <FormGroup Label="Password">
          <Input placeholder="Password" />
        </FormGroup> */}
          <Button
            onClick={() => {
              setChangePasswordStep(true);
            }}
            className="btn btn-primary w-100 justify-content-center  mt-4"
          >
            Change password
          </Button>
          <div className="or pb-4"></div>
          <Button className="btn btn-light w-100 justify-content-center ">
            Deactivate account
          </Button>
        </form>
      ) : (
        <form className="changePasswordForm" onSubmit={onSubmit}>
          <h2>Change password</h2>
          <FormGroup
            errorMessage={
              errors.currentPassword?.type === "required"
                ? "Current password is required"
                : errors.currentPassword?.type === "minLength"
                ? "Password must be at least 6 characters"
                : ""
            }
            Label="Current password"
          >
            <Input
              useRef={register("currentPassword", {
                required: "currentPassword is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              onChange={() => {
                clearErrors("currentPassword");
                // clearErrors("currentPassword");
                // trigger("currentPassword");
              }}
              className={"mb-3"}
              name="currentPassword"
              type="password"
              placeholder="currentPassword"
              hasError={!!errors.currentPassword}
            />
          </FormGroup>
          <FormGroup
            errorMessage={
              errors.password?.type === "required"
                ? "Password is required"
                : errors.password?.type === "minLength"
                ? "Password must be at least 6 characters"
                : ""
            }
            // className="mb-3"
            Label="New password"
          >
            <Input
              useRef={register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              onChange={() => {
                clearErrors("password");
                clearErrors("passwordConfirm");
                trigger("passwordConfirm"); // trigger revalidation of passwordConfirm
              }}
              className={"mb-3"}
              name="password"
              type="password"
              placeholder="Password"
              hasError={!!errors.password}
            />
          </FormGroup>
          <FormGroup
            errorMessage={
              errors.passwordConfirm?.type === "required"
                ? "Confirm Password is required"
                : errors.passwordConfirm?.type === "validate"
                ? "Passwords do not match"
                : ""
            }
            // className="mb-3"
            Label="Re-type password"
          >
            <Input
              useRef={register("passwordConfirm", {
                required: "Confirm password is required",
                validate: value =>
                  value === watch("password") || "Passwords do not match",
              })}
              className={"mb-3"}
              onChange={() => clearErrors("passwordConfirm")}
              name="passwordConfirm"
              type="password"
              placeholder="Confirm password"
              hasError={!!errors.passwordConfirm}
            />
          </FormGroup>
          {/* <FormGroup Label="Password">
        <Input placeholder="Password" />
      </FormGroup> */}
          <div className="d-flex">
            <Button
              onClick={() => {
                setChangePasswordStep(false);
              }}
              className="btn btn-light w-100 mr-2 justify-content-center  mt-4"
            >
              Go Back
            </Button>
            <Button
              loading={load}
              onClick={() => {
                setChangePasswordStep(true);
              }}
              className="btn btn-primary ml-2 w-100 justify-content-center  mt-4"
            >
              Confirm
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
