import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input } from "../components/common/form";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { AuthService } from "../api/auth/auth.http";
import { VpnService } from "../api/vpnService/vpnService.http";

type FormData = {
  // currentPassword: string;
  password: string;
  passwordConfirm?: string;
};

export const VpnDetails = () => {
  const [load, setLoad] = useState(false);
  const [changePasswordStep, setChangePasswordStep] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormData>();
  const {
    auth: { meta },
  } = useSelector((state: any) => state);

  useEffect(() => {
    setValue("password", "");
  }, []);

  const onSubmit = handleSubmit(async formData => {
    setLoad(true);
    try {
      let res = await VpnService.changePassword(formData.password);
      toast.success("Password changed successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      // console.log(error.data);
      // if (err.data?.message?.current_password) {
      //   toast.error(err.data.message?.current_password, {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // }
      if (error.response.data.message) {
        let message;
        if (typeof error.response.data.message === "string") {
          message = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          message = error.response.data.message[0];
        } else if (typeof error.response.data.message === "object") {
          message = Object.values(error.response.data.message)[0];
        }

        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setLoad(false);
    }
  });
  return (
    <div className="vpnForm">
      <ToastContainer />

      {!changePasswordStep ? (
        <form autoComplete="off">
          <h2>VPN account info</h2>
          <FormGroup Label={"Username"}>
            <Input
              value={meta?.vpn?.data?.username}
              readonly={true}
              className="mb-4"
              placeholder="Usernameexample123"
            />
          </FormGroup>
          {/* <FormGroup className="" Label={"Password"}>
          <Input placeholder="Password" />
        </FormGroup> */}
          <div className="mt-4">
            <Button
              onClick={e => {
                e.preventDefault();
                setChangePasswordStep(true);
              }}
              className="btn btn-light w-100 justify-content-center"
            >
              Change password
            </Button>
          </div>
        </form>
      ) : (
        <form className="changePasswordForm" onSubmit={onSubmit}>
          <h2>Change password</h2>
          {/* <FormGroup
            errorMessage={
              errors.currentPassword?.type === "required"
                ? "Current password is required"
                : errors.currentPassword?.type === "minLength"
                ? "Password must be at least 6 characters"
                : ""
            }
            Label="Current password"
          >
            <Input
              useRef={register("currentPassword", {
                required: "currentPassword is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              onChange={() => {
                clearErrors("currentPassword");
                // clearErrors("currentPassword");
                // trigger("currentPassword");
              }}
              className={"mb-3"}
              name="currentPassword"
              type="password"
              placeholder="currentPassword"
              hasError={!!errors.currentPassword}
            />
          </FormGroup> */}
          <FormGroup
            errorMessage={
              errors.password?.type === "required"
                ? "Password is required"
                : errors.password?.type === "minLength"
                ? "Password must be at least 6 characters"
                : ""
            }
            // className="mb-3"
            Label="New password"
          >
            <Input
              useRef={register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              onChange={() => {
                clearErrors("password");
                clearErrors("passwordConfirm");
                trigger("passwordConfirm"); // trigger revalidation of passwordConfirm
              }}
              className={"mb-3"}
              name="password"
              type="password"
              placeholder="Password"
              hasError={!!errors.password}
            />
          </FormGroup>
          <FormGroup
            errorMessage={
              errors.passwordConfirm?.type === "required"
                ? "Confirm Password is required"
                : errors.passwordConfirm?.type === "validate"
                ? "Passwords do not match"
                : ""
            }
            // className="mb-3"
            Label="Re-type password"
          >
            <Input
              useRef={register("passwordConfirm", {
                required: "Confirm password is required",
                validate: value =>
                  value === watch("password") || "Passwords do not match",
              })}
              className={"mb-3"}
              onChange={() => clearErrors("passwordConfirm")}
              name="passwordConfirm"
              type="password"
              placeholder="Confirm password"
              hasError={!!errors.passwordConfirm}
            />
          </FormGroup>
          {/* <FormGroup Label="Password">
        <Input placeholder="Password" />
      </FormGroup> */}
          <div className="d-flex">
            <Button
              onClick={e => {
                e.stopPropagation();
                setChangePasswordStep(false);
              }}
              className="btn btn-light w-100 mr-2 justify-content-center  mt-4"
            >
              Go Back
            </Button>
            <Button
              loading={load}
              onClick={() => {
                setChangePasswordStep(true);
              }}
              className="btn btn-primary ml-2 w-100 justify-content-center  mt-4"
            >
              Confirm
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
