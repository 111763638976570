import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SetUser } from "../../../redux/actions";
import { Button } from "../../common/form";
import { RegisterContext } from "../registerContext/registerContext";

export const SaveAccountStep = () => {
  const dispatch = useDispatch();
  const { user } = useContext(RegisterContext);
  const navigate = useNavigate();
  const [copySuccess, setCopySuccess] = useState(false);

  const loginUser = () => {
    dispatch(SetUser({ user, token: user.token }));
    navigate("/dashboard");
  };

  const handleCopyClick = () => {
    const username = user?.meta?.vpn?.data?.username;
    const password = user?.meta?.vpn?.data?.password;
    const credentials = `Username: ${username} ----  Password: ${password}`;
    navigator.clipboard.writeText(credentials).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 3000);
    });
  };

  return (
    <div className="registerStep mw-100">
      <h2>Download APP for your device:</h2>
      <p className="text-center mt-5">Your vpn username and password are:</p>
      <div className="passwordBox">
        <div>
          <b>Username:</b> <span>{user?.meta?.vpn?.data?.username}</span>
        </div>
        <div>
          <b className="mb-0">Password:</b>{" "}
          <span>{user?.meta?.vpn?.data?.password}</span>
        </div>
      </div>
      <div className="text-center d-flex justify-content-center mt-4">
        <Button className="btn" onClick={handleCopyClick}>
          {copySuccess ? "Copied!" : "Copy"}
        </Button>
      </div>

      <p className="text-center text mt-4">
        Please save your details, you need to have these credentials <br />
        to enter in the APP to be able to connect to vpn.
      </p>
      <div className="line"></div>
      <div className="downloadGroup">
        <a href="https://apps.apple.com/app/id1506797696" target="_blank">
          <img src="./App Store Black.svg" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.vpn.client"
          target="_blank"
        >
          <img src="./Google Play Black.svg" />
        </a>
        <a
          href="https://vpnclient.app/current/vpnclient/vpnclient.exe"
          target="_blank"
        >
          <img src="./Microsoft Black.svg" />
        </a>
        <a
          href="https://vpnclient.app/current/vpnclient/vpnclient.dmg"
          target="_blank"
        >
          <img src="./Mac App Stor.svg" />
        </a>
        <a
          href="https://321inter.net/downloads/VPNClient-tv.apk"
          target="_blank"
        >
          <img src="./Group 6.svg" />
        </a>
      </div>
      <div className="miniCOntainer">
        <div className="or">
          <div>OR</div>
        </div>
        <Button
          onClick={loginUser}
          className="btn w-100 justify-content-center"
        >
          Finish
        </Button>
      </div>
    </div>
  );
};
