import React from "react";

export const VpnSoftware = () => {
  return (
    <div className="pnSoftware">
      <div>
        <h2>Download APP for your device:</h2>
        <div className="downloadGroup d-none d-md-flex">
          <a href="https://apps.apple.com/app/id1506797696" target="_blank">
            <img src="../App Store Black.svg" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.vpn.client"
            target="_blank"
          >
            <img src="../Google Play Black.svg" />
          </a>
          <a
            href="https://vpnclient.app/current/vpnclient/vpnclient.exe"
            target="_blank"
          >
            <img src="../Microsoft Black.svg" />
          </a>
        </div>
        <div className="downloadGroup d-none d-md-flex justify-content-center">
          <a
            href="https://vpnclient.app/current/vpnclient/vpnclient.dmg"
            target="_blank"
          >
            <img src="../Mac App Stor.svg" />
          </a>
          <a
            href="https://321inter.net/downloads/VPNClient-tv.apk"
            target="_blank"
          >
            <img src="../Group 6.svg" />
          </a>
        </div>

        <div className="downloadGroup d-flex d-md-none ">
          <a href="https://apps.apple.com/app/id1506797696" target="_blank">
            <img src="../App Store Black.svg" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.vpn.client"
            target="_blank"
          >
            <img src="../Google Play Black.svg" />
          </a>
          <a
            href="https://vpnclient.app/current/vpnclient/vpnclient.exe"
            target="_blank"
          >
            <img src="../Microsoft Black.svg" />
          </a>
          <a
            href="https://321inter.net/downloads/VPNClient-tv.apk"
            target="_blank"
          >
            <img src="../Group 6 (1).svg" />
          </a>
        </div>
        <div className="downloadGroup d-flex d-md-none">
          <a
            className="mw-100"
            href="https://vpnclient.app/current/vpnclient/vpnclient.dmg"
            target="_blank"
          >
            <img src="../Mac App Stor.svg" />
          </a>
        </div>
      </div>
    </div>
  );
};
