import React, { useEffect, useState } from "react";

interface IProps {
  value?: string;
  defaultValue?: string;
  options?: IOption[];
  onChange?: (IOption) => void;
}

export interface IOption {
  value: string;
  id: number;
}

const CustomSelect: React.FC<IProps> = ({
  defaultValue,
  value,
  options,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectOption = (option: IOption) => {
    onChange && onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const closeSelect = () => setIsOpen(false);
    window.addEventListener("click", closeSelect);

    return () => window.removeEventListener("click", closeSelect);
  }, []);

  return (
    <div onClick={e => e.stopPropagation()} className="select">
      <div className="selectInput" onClick={() => setIsOpen(!isOpen)}>
        <span>
          {value ? value : defaultValue ? defaultValue : "Select an option"}
        </span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`arrow ${isOpen ? "open" : ""}`}
        >
          <path
            d="M7.41 8.59L6 10L12 16L18 10L16.59 8.59L12 13.17L7.41 8.59Z"
            fill="#D0D0D0"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="selectOptions">
          {options?.map(el => {
            return (
              <div
                key={el.id}
                className="option"
                onClick={() => handleSelectOption(el)}
              >
                {el.value}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
