import { STORE_API_BASE_URL } from "./api";

export const backEndRoutes = {
  auth: {
    login: () => `${STORE_API_BASE_URL}​/api/login`,
    register: () => `${STORE_API_BASE_URL}​/api/register`,
    resetPass: () => `${STORE_API_BASE_URL}​/api/reset/password`,
    reCoverPass: () => `${STORE_API_BASE_URL}​/api/recover/password`,
    changePassword: () => `${STORE_API_BASE_URL}​/api/profile/change/pass`,
    getUser: (token: string) => `${STORE_API_BASE_URL}​/api/getUser/${token}`,
    getInfo: () => `${STORE_API_BASE_URL}​/api/total/users`,

    confirmCode: ({ user_id, code }) =>
      `${STORE_API_BASE_URL}/api/verify/email/${user_id}/${code}`,
  },
  vpnService: {
    register: () => `${STORE_API_BASE_URL}​/api/vpnsellers/account`,
    changePassword: pass =>
      `${STORE_API_BASE_URL}​/api/vpnsellers/change/password/${pass}`,
    getServersAndPorts: () =>
      `${STORE_API_BASE_URL}​/api/vpnsellers/configuration/data`,
    configExport: (serverID, portID) =>
      `${STORE_API_BASE_URL}​/api/vpnsellers/configuration/${serverID}/${portID}`,

    WireGuardConfigExport: serverID =>
      `${STORE_API_BASE_URL}​/api/vpnsellers/wireguardConfiguration/${serverID}`,
  },
};
