import React, { useEffect, useState } from "react";
import { RegisterStepIndicator } from "../components/register/registerstepindicator";
import { RegisterProvider } from "../components/register/registerContext/registerContext";
import RegisterSteps from "../components/register/registerSteps";
import { Button, FormGroup, Input } from "../components/common/form";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { AuthService } from "../api/auth/auth.http";
import queryString from "query-string";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

type FormData = {
  email: string;
  new_password: string;
  pass_recover: string;
};

export default function RecoverPassword() {
  const [isLoading, setIsLoading] = useState(false);
  // const [searchParams, setParams] = setEmail();
  const location = useLocation();
  const redirect = useNavigate();
  console.log(queryString.parse(location.search), "dddddddddddddddddd");

  useEffect(() => {
    if (!queryString.parse(location.search).email) {
      redirect("/resetpassword");
    }
  }, [location.search]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<FormData>();

  const onSubmit = handleSubmit(async formData => {
    try {
      setIsLoading(true);
      const response: any = await AuthService.reCoverPass({
        ...formData,
        email: queryString.parse(location.search).email,
      });

      toast.success("Password changed! now you can sign in with new password", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate("/");
      }, 3000);

      // Do something with the response, like redirect the user to the dashboard
    } catch (error) {
      if (error.data.message) {
        const message =
          typeof error.data.message === "string"
            ? error.data.message
            : Object.values(error.data.message)[0];
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // Handle the error, such as displaying an error message to the user
    } finally {
      setIsLoading(false);
    }
  });
  return (
    <section>
      <ToastContainer />
      <h2 className="text-center mb-5 pb-3 mt-5">Reset password</h2>

      <div className="register_wrapper recoverPassword">
        <p
          style={{
            maxWidth: 500,
            margin: "auto",
            textAlign: "center",
          }}
        >
          A password reset code has been sent to your email. Please check your
          inbox and enter the code below, along with your new password to reset
          your account password. If you haven't received the email, please check
          your spam folder or try <Link to={"/resetpassword"}> resending </Link>{" "}
          the code.
        </p>
        <br />
        <form autoComplete="off" onSubmit={onSubmit}>
          <FormGroup
            errorMessage={
              errors.email?.type === "required"
                ? "Email is required"
                : errors.email?.type === "pattern"
                ? "Please enter a valid email address"
                : ""
            }
          >
            <Input
              value={queryString.parse(location.search).email as string}
              name="email"
              type="text"
              placeholder="example@mail.com"
            />
          </FormGroup>

          <FormGroup
            errorMessage={
              errors.new_password?.type === "required"
                ? "Password is required"
                : errors.new_password?.type === "minLength"
                ? "Password must be at least 6 characters"
                : ""
            }
          >
            <Input
              useRef={register("new_password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              onChange={() => {
                clearErrors("new_password");
                // clearErrors("passwordConfirm");
                // trigger("passwordConfirm"); // trigger revalidation of passwordConfirm
              }}
              name="new_password"
              type="password"
              placeholder="New Password"
              hasError={!!errors.new_password}
            />
          </FormGroup>

          <FormGroup
            errorMessage={
              errors.pass_recover?.type === "required"
                ? "Recover code is required"
                : errors.pass_recover?.type === "validate"
                ? "Passwords do not match"
                : ""
            }
          >
            <Input
              useRef={register("pass_recover", {
                required: "Confirm password is required",
              })}
              onChange={() => clearErrors("pass_recover")}
              name="pass_recover"
              type="password"
              placeholder="Recover Code"
              hasError={!!errors.pass_recover}
            />
          </FormGroup>

          <div className="nextButton d-flex align-items-center  mt-4 justify-content-center ">
            {/* <div>
            <span>Already have an account?</span>
            <Link to={"/"}>Log in</Link>
          </div> */}
            <Button className="btn btn-default px-5" loading={isLoading}>
              Send
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}
