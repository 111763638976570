import React, { useEffect, useState } from "react";
import { inherits } from "util";
import { VpnService } from "../api/vpnService/vpnService.http";
import { Button } from "../components/common/form";
import Select, { IOption } from "../components/common/form/select";
import { useCheckAuth } from "../utility/useCheckAuth";

export const VpnConfig = () => {
  const [post, setPort] = useState();
  const [load, setLoad] = useState(false);
  const [loadWireguard, setLoadWIreguard] = useState(false);
  const [selectedServer, setSelectedServer] = useState<IOption | null>(null);
  const [
    selectedWireguardServer,
    setSelectedWireguardServer,
  ] = useState<IOption | null>(null);
  const [selectedPort, setSelectedPort] = useState<IOption | null>(null);
  const [
    selectedWireguardPort,
    setSelectedWireguardPort,
  ] = useState<IOption | null>(null);
  const [seversAndPorts, setServersAndPots] = useState<{
    ports: IOption[];
    servers: IOption[];
  }>();
  useCheckAuth();

  const serverEndPortNormalizer = (data: {
    ports: { data: any[] };
    servers: { data: any[] };
  }) => {
    let ports = data.ports.data.map(el => {
      return { id: el.id, value: el.protocol + "-" + el.number };
    });
    let servers = data.servers.data.map(el => {
      return { id: el.id, value: el.city };
    });
    return {
      ports,
      servers,
    };
  };
  useEffect(() => {
    VpnService.getServersAndPorts()
      .then(res => {
        // console.log(res);
        // setPort(res.data.)
        let serversAndPorts = serverEndPortNormalizer(res.data);

        setServersAndPots(serversAndPorts);
        setSelectedPort(serversAndPorts.ports[0]);
        setSelectedWireguardPort(serversAndPorts.ports[0]);
        setSelectedServer(serversAndPorts.servers[0]);
        setSelectedWireguardServer(serversAndPorts.servers[0]);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const downloadConfig = (config, fileNamePrefix = "openWPN") => {
    const element = document.createElement("a");
    const file = new Blob([config], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    let fileName = null;
    if (fileNamePrefix === "openWPN") {
      fileName = `config-${selectedServer.value}-${selectedPort.value}.ovpn`;
    } else {
      fileName = `config-${selectedWireguardServer.value}.conf`;
    }
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const generateConfig = async () => {
    setLoad(true);

    try {
      let res = await VpnService.getConfigFile(
        selectedServer.id,
        selectedPort.id,
      );
      downloadConfig(res.data, "openWPN");
    } catch (err) {
      console.log(err);
    } finally {
      setLoad(false);
    }
  };

  const generateWireguardConfig = async () => {
    setLoadWIreguard(true);
    try {
      let res = await VpnService.getWireGuardConfigFile(selectedServer.id);
      downloadConfig(res.data, "wireGuard");
    } catch (err) {
      console.log(err);
    } finally {
      setLoadWIreguard(false);
    }
  };
  return (
    <div className="VpnConfig">
      <div>
        <h2>
          Generate config files openvpn/wireguard <br />
          for routers or manual installations
        </h2>
        <p className="mt-5">
          Create your OpenVPN config file here with your <br />
          desired server settings
        </p>
        <div className="d-flex flex-column flex-md-row">
          <div className="mr-4">
            <Select
              options={seversAndPorts?.servers}
              onChange={selectedOption => {
                // console.log(selectedOption);
                setSelectedServer(selectedOption);
              }}
              defaultValue="Amsterdam"
              value={selectedServer?.value}
            />
          </div>
          <div className="mx-md-4 mt-3 mt-md-0">
            <Select
              onChange={selectedOption => {
                // console.log(selectedOption);
                setSelectedPort(selectedOption);
              }}
              options={seversAndPorts?.ports}
              value={selectedPort?.value}
              defaultValue="UDP - 53"
            />
          </div>
          <Button
            loading={load}
            onClick={generateConfig}
            className="btn w-100 m-md-auto  mt-3 mt-md-0"
          >
            Generate
          </Button>
        </div>
        <p className="mt-5">
          Create your Wireguard config here with your <br />
          desired server location and username settings
        </p>
        <div className="d-flex flex-column flex-md-row ">
          <div className="mr-4">
            <Select
              options={seversAndPorts?.servers}
              onChange={selectedOption => {
                // console.log(selectedOption);
                setSelectedWireguardServer(selectedOption);
              }}
              defaultValue="Amsterdam"
              value={selectedWireguardServer?.value}
            />
          </div>
          <div
            style={{ opacity: 0, cursor: "inherit" }}
            className="mx-md-4 mt-3 mt-md-0 withoutCursor"
          >
            <Select
              onChange={selectedOption => {
                console.log(selectedOption);
                setSelectedWireguardPort(selectedOption);
              }}
              options={seversAndPorts?.ports}
              value={selectedWireguardPort?.value}
              defaultValue="UDP - 53"
            />
          </div>
          <Button
            loading={loadWireguard}
            onClick={generateWireguardConfig}
            className="btn w-100 m-md-auto mt-3 mt-md-0"
          >
            Generate
          </Button>
        </div>
      </div>
    </div>
  );
};
