import React, { useEffect } from "react";
import { Login } from "../../components/login";
import { useDispatch, useSelector } from "react-redux";

import { fetchTodo } from "../../redux/actions";
import { Button } from "../../components/common/form";
import { IFetchTodoAction } from "../../redux/actions";
import { useCheckAuth } from "../../utility/useCheckAuth";
import { useNavigate } from "react-router-dom";

function Home() {
  useCheckAuth();
  const navigate = useNavigate();
  const { auth } = useSelector((state: any) => state);
  useEffect(() => {
    // console.log(auth.user, "user");
    if (auth.user) {
      navigate("/dashboard");
    }
  }, [auth]);
  return (
    <section className="homePage">
      <div className="container">
        <div className="row">
          <div className="col-md-5 d-flex justify-content-center align-items-center">
            <Login />
          </div>
          <div className="col-md-7 text-right">
            <img height="560" src="./vpncrypto-protocol-gif-transparent.gif" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
